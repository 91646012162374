import { MoreVerticalIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Box, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { DeleteVolumeModal } from './DeleteVolumeModal';
import { EditVolumeModal } from './EditVolumeModal';

type VolumeTableRowDropdownProps = {
  name?: string;
  volumeId?: string;
  organizationId?: string;
  description?: string;
  selectedOrganizationId?: string;
  permission?: string;
  tags?: string[];
  refetch?: VoidFunction;
};

export const VolumeTableRowDropdown = ({
  name,
  volumeId,
  organizationId,
  selectedOrganizationId,
  description,
  permission,
  tags,
  refetch,
}: VolumeTableRowDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isVolumeOwnedByOrganization = selectedOrganizationId === organizationId;
  const { showModal: showEditVolumeModal } = useModal({
    component: EditVolumeModal,
    componentProps: {
      name,
      description,
      volumeId,
      organizationId: selectedOrganizationId,
      permission,
      tags,
      refetch,
    },
    modalProps: { title: 'Edit volume' },
  });
  const { showModal: showDeleteVolumeModal } = useModal({
    component: DeleteVolumeModal,
    componentProps: {
      name,
      volumeId,
      organizationId: selectedOrganizationId,
      isVolumeOwnedByOrganization,
    },
    modalProps: { title: `${isVolumeOwnedByOrganization ? 'Delete' : 'Remove'} volume` },
  });
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleEditVolumeClick = () => {
    showEditVolumeModal();
    handleClose();
  };
  const handleDeleteVolumeClick = () => {
    showDeleteVolumeModal();
    handleClose();
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        Icon={MoreVerticalIcon}
        size={20}
        data-cy="Volume-Table-Row-Dropdown-Icon"
      />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {isVolumeOwnedByOrganization ? (
          <Box>
            <MenuItem onClick={handleEditVolumeClick}>
              <Typography variant="caption2" data-cy="Volume-Table-Row-Dropdown-Edit">
                Edit
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleDeleteVolumeClick}>
              <Typography variant="caption2" data-cy="Volume-Table-Row-Dropdown-Delete">
                Delete
              </Typography>
            </MenuItem>
          </Box>
        ) : (
          <Box>
            <MenuItem onClick={handleDeleteVolumeClick}>
              <Typography variant="caption2" data-cy="Volume-Table-Row-Dropdown-Remove">
                Remove
              </Typography>
            </MenuItem>
          </Box>
        )}
      </Menu>
    </Box>
  );
};
