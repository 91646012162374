import { FormSelect } from '@components/form';
import { Tag } from '@components/tag';
import { useGetVolumesQuery } from '@generated/UseGraphqlHooks';
import { MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import { useField } from 'formik';
import { filter, find, orderBy, toLower } from 'lodash';
import { MouseEvent } from 'react';

interface VolumesWithAccessDropdownProps {
  name: string;
  label: string;
  organizationId: string;
  channelVolumesAndTimeoutDataLoading?: boolean;
}

export const VolumesWithAccessDropdown = (props: VolumesWithAccessDropdownProps) => {
  const { organizationId, label, name, channelVolumesAndTimeoutDataLoading } = props;
  const [field, meta, helpers] = useField(props);
  const { value } = field as { value: string[] };
  const { setValue } = helpers;

  const { data: getVolumesData, loading } = useGetVolumesQuery({
    variables: {
      organizationId,
    },
  });
  const orderedVolumes = orderBy(
    getVolumesData?.getVolumes,
    (volume) => toLower(volume.name),
    'asc',
  );

  const handleChange = ({ target: { value: targetValue } }: SelectChangeEvent<unknown>) => {
    setValue(targetValue);
  };

  const handleDelete = (_: MouseEvent, channelId: string) => {
    const removedValue = filter(value, (id) => id !== channelId);
    setValue(removedValue);
  };

  const getVolumeName = (volumeId: string) => {
    return find(orderedVolumes, { volumeId })?.name;
  };

  const ready = !loading || !channelVolumesAndTimeoutDataLoading;

  return (
    <FormSelect
      multiple
      name={name}
      value={value}
      label={label}
      onChange={handleChange}
      renderValue={(selected: string[]) => (
        <Stack direction="row" gap={1} flexWrap="wrap">
          {selected.map((id) => (
            <Tag
              key={id}
              label={getVolumeName(id)}
              onDelete={(e) => handleDelete(e, id)}
              sx={{ height: '21px', '& .MuiChip-label': { fontSize: '12px', lineHeight: '21px' } }}
            />
          ))}
        </Stack>
      )}
    >
      {ready ? (
        orderedVolumes.map((volume) => (
          <MenuItem key={volume.volumeId} value={volume.volumeId}>
            {getVolumeName(volume.volumeId)}
          </MenuItem>
        ))
      ) : (
        <MenuItem value="">Loading...</MenuItem>
      )}
    </FormSelect>
  );
};
