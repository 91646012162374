import { Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { FormTagsInput } from '@components/form/FormInputs/FormTagsInput';
import { useEditWorkspaceMutation, useGetChannelsQuery } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { FormikProps } from 'formik';
import * as yup from 'yup';

interface EditModalProps {
  onClose: () => void;
  workspaceId: string;
  refetch?: VoidFunction;
  workspaceName: string;
  description: string;
  tags: string[];
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
});

export const WorkspacesMoreDropdownEditModal = ({
  onClose,
  workspaceId,
  refetch,
  workspaceName,
  description: workspaceDescription,
  tags: workspaceTags,
}: EditModalProps) => {
  const { useAsyncNotification } = useNotifications();

  const { data: workspaceChannels } = useGetChannelsQuery({
    variables: {
      workspaceId,
    },
  });

  const workspaceChannelsIds = workspaceChannels?.getChannels.map(
    (organization) => organization.channelId,
  );

  const [editWorkspaceMutation] = useEditWorkspaceMutation();
  const handleSubmit = useAsyncNotification(
    'Successfully edited workspace',
    async ({ name, description, tags }: { name: string; description: string; tags: string }) => {
      onClose();
      await editWorkspaceMutation({
        variables: {
          workspaceId,
          name,
          description,
          tags,
          channelIds: workspaceChannelsIds,
        },
      });
      if (refetch) {
        await refetch();
      }
    },
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        name: workspaceName,
        description: workspaceDescription || '',
        tags: workspaceTags || [],
      }}
      validationSchema={validationSchema}
    >
      {({ errors }: FormikProps<{ workspaceName: string }>) => (
        <Stack>
          <FormTextInput label="Name" name="name" />
          <FormTextInput name="description" label="Description" multiline rows={3} />
          <FormTagsInput name="tags" />
          <Stack gap={4}>
            <Button
              fullWidth
              disabled={!!errors?.workspaceName}
              data-cy="Workspaces-More-Dropdown-Rename-Modal-Save-Button"
              type="submit"
            >
              Save
            </Button>
            <Button
              fullWidth
              variant="secondary"
              onClick={onClose}
              data-cy="Workspaces-More-Dropdown-Rename-Modal-Cancel-Button"
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
