import { PlusIcon } from '@assets/icons';
import { Button, TableView, TableViewButton } from '@components/button';
import { DeckardFilter, SearchAndFilter } from '@components/filter';
import { Group } from '@components/layout';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import {
  WorkspaceFilter,
  WorkspaceSearchDetail,
  useGetOrganizationSettingsQuery,
} from '@generated/UseGraphqlHooks';
import { useFeatureFlag } from '@hooks/FeatureFlags';
import { Box, Stack } from '@mui/material';
import {
  NewWorkspacesModal,
  NewWorkspacesModalHelpContent,
} from '@subsets/workspaces/workspaces-modals';
import { find } from 'lodash';
import posthog from 'posthog-js';

const CreateWorkspaceButton = ({
  organizationId,
  organizationName,
  refetch,
}: {
  organizationId: string;
  organizationName?: string;
  refetch: VoidFunction;
}) => {
  const { data: organizationSettingsData, loading: orgSettingsLoading } =
    useGetOrganizationSettingsQuery({
      variables: { organizationId },
    });

  const { usage, limit } =
    (!orgSettingsLoading &&
      find(organizationSettingsData?.getOrganizationSettings, {
        setting: 'Workspaces',
      })) ||
    {};
  const { refetch: settingsRefetch } = useGetOrganizationSettingsQuery({
    variables: { organizationId },
  });
  const workspacesRefetch = async () => {
    if (refetch) {
      refetch();
    }
    settingsRefetch();
  };
  const { showModal } = useModal({
    component: NewWorkspacesModal,
    componentProps: {
      organizationId,
      organizationName,
      refetch: workspacesRefetch,
    },
    modalProps: { title: 'New workspace', help: NewWorkspacesModalHelpContent },
  });

  const isOrganizationWorkspacesReachedLimit = Number(usage) === Number(limit);

  return (
    <Button
      variant="accent"
      disabled={isOrganizationWorkspacesReachedLimit}
      startIcon={PlusIcon}
      onClick={() => {
        posthog.capture('user_create-workspace_intent', { location: 'organization-landing' });
        showModal();
      }}
    >
      New workspace
    </Button>
  );
};

type LandingPageWorkspacesHeaderProps = {
  filter: DeckardFilter<WorkspaceFilter>;
  organizationId: string;
  organizationName?: string;
  label: string;
  searchDetail: WorkspaceSearchDetail;
  tableView: TableView;
  refetch: VoidFunction;
  setFilter: (newFilter: DeckardFilter<WorkspaceFilter>) => void;
  setTableView: (newView: TableView) => void;
};

export const LandingPageWorkspacesHeader = ({
  filter,
  organizationId,
  organizationName,
  label,
  searchDetail,
  tableView,
  refetch,
  setFilter,
  setTableView,
}: LandingPageWorkspacesHeaderProps) => {
  const filterFlag = useFeatureFlag('feature_tagging-search-and-filter');
  if (organizationId) {
    return (
      <Stack gap={8} mb={5}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography maxWidth="400px" variant="h1" noWrap handleOverFlow>
            {label}
          </Typography>
          {organizationId && (
            <CreateWorkspaceButton
              organizationId={organizationId}
              organizationName={organizationName}
              refetch={refetch}
            />
          )}
        </Stack>
        <Group justifyContent="space-between" alignItems="flex-start" gap={8}>
          {filterFlag ? (
            <SearchAndFilter
              filter={filter}
              setFilter={(newFilter) => {
                console.log('new filter', newFilter);
                setFilter(newFilter);
              }}
              searchDetail={searchDetail}
            />
          ) : (
            <Box flex={1} />
          )}
          <TableViewButton mode={tableView} onChange={(newView) => setTableView(newView)} />
        </Group>
      </Stack>
    );
  }

  return (
    <Group gap={8} mb={5} justifyContent="space-between" data-cy="Landing-Page-Workspaces">
      <Typography maxWidth="400px" variant="h1" noWrap handleOverFlow>
        {label} workspaces
      </Typography>
    </Group>
  );
};
