import { LoadingIcon } from '@assets/icons';
import { DeckardFilter, SearchAndFilter } from '@components/filter';
import { InfiniteScrollTableComponents, useGraphqlPagination } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import {
  AnnotationMap,
  AnnotationMapFilter,
  GetAnnotationMapsQuery,
  useGetAnnotationMapsQuery,
  useGetAnnotationMapsSearchDetailQuery,
} from '@generated/UseGraphqlHooks';
import { useFeatureFlag } from '@hooks/FeatureFlags';
import { Box, Stack, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { AnnotationMapHeader } from './AnnotationMapHeader';
import { AnnotationMapTableHeader } from './AnnotationMapTableHeader';
import { AnnotationMapTableRow } from './AnnotationMapTableRow';

type OrganizationAnnotationMapsProps = {
  name: string;
  organizationId: string;
};

export const OrganizationAnnotationMaps = ({
  name,
  organizationId,
}: OrganizationAnnotationMapsProps) => {
  const { palette } = useTheme();

  const filterFlag = useFeatureFlag('feature_tagging-search-and-filter');
  const [filter, setFilter] = useState<DeckardFilter<AnnotationMapFilter>>({
    dates: { type: 'date', value: [], display: [] },
    tags: { type: 'string', value: [], display: [] },
    texts: { type: 'string', value: [], display: [] },
    users: { type: 'exact', value: [], display: [] },
  });

  const queryFilter = useMemo(() => {
    return Object.entries(filter)
      .filter(([_, value]) => value.value.length > 0)
      .reduce((acc, [key, value]) => {
        acc[key] = value.value;
        return acc;
      }, {} as AnnotationMapFilter);
  }, [filter]);
  const { data, refetch: refetchSearchDetail } = useGetAnnotationMapsSearchDetailQuery({
    variables: { organizationId, filters: queryFilter },
  });
  const searchDetail = data?.getAnnotationMapsSearchDetail;

  const {
    data: annotationMaps,
    loading: annotationMapsLoading,
    fetchMore,
    refetch: refetchAnnotationMaps,
  } = useGraphqlPagination<GetAnnotationMapsQuery, AnnotationMap>(
    useGetAnnotationMapsQuery({
      variables: { organizationId, limit: 30, filters: queryFilter },
    }),
    'getAnnotationMaps',
    'mapId',
  );

  const refetch = () => {
    refetchAnnotationMaps();
    refetchSearchDetail();
  };

  const tableRow = (_index, annotationMap: AnnotationMap) => (
    <AnnotationMapTableRow
      {...annotationMap}
      selectedOrganizationId={organizationId}
      refetch={refetch}
    />
  );

  return (
    <Stack pt={5} pl={10} spacing={5} height="100%" overflow="hidden">
      <AnnotationMapHeader name={name} organizationId={organizationId} refetch={refetch} />
      <Stack
        flex={1}
        overflow="hidden"
        gap={4}
        p={6}
        sx={{ backgroundColor: palette.background.paper }}
      >
        {filterFlag && (
          <SearchAndFilter
            filter={filter}
            setFilter={setFilter}
            searchDetail={searchDetail}
            variant="secondary"
          />
        )}
        {annotationMapsLoading ? (
          <Stack
            bgcolor={palette.background.paper}
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingIcon size={16} />
          </Stack>
        ) : annotationMaps.length === 0 ? (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Typography>This Organization has no Annotation Maps.</Typography>
          </Box>
        ) : (
          <TableVirtuoso
            data={annotationMaps}
            endReached={fetchMore}
            components={InfiniteScrollTableComponents as any}
            fixedHeaderContent={AnnotationMapTableHeader}
            itemContent={tableRow}
            increaseViewportBy={400}
          />
        )}
      </Stack>
    </Stack>
  );
};
