import { CopyIconButton } from '@components/button';
import { Typography } from '@components/typography';
import { formatTime } from '@helper-functions/time';
import { Box, TableCell } from '@mui/material';
import { memo } from 'react';
import { GanModelTableRowDropdown } from './GanModelTableRowDropdown';

interface GanModelTableRowProps {
  name: string;
  modelId?: string;
  description?: string;
  updatedAt?: string;
  organization?: string;
  selectedOrganizationId?: string;
  tags: string[];
  refetch?: VoidFunction;
}

export const GanModelTableRow = memo((props: GanModelTableRowProps) => {
  const { name, updatedAt, modelId, description, organization } = props;

  return (
    <>
      <TableCell>
        <CopyIconButton value={modelId} showValue />
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4} data-cy="Gan-Model-Table-Row-Name">
          {name || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4}>
          {description || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4}>
          {organization}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography noWrap handleOverFlow variant="body2" pr={4}>
            {formatTime(updatedAt, 'date') || '-'}
          </Typography>
          <Box pr={4}>
            <GanModelTableRowDropdown {...props} />
          </Box>
        </Box>
      </TableCell>
    </>
  );
});
