import { Group } from '@components/layout';
import { Tag } from '@components/tag';
import { useFeatureFlag } from '@hooks/FeatureFlags';
import { Stack, StackProps } from '@mui/material';
import { useField } from 'formik';
import { useState } from 'react';
import { TextInput } from '../TextInput';
import { FormFieldError } from './FormFieldError';

type FormTagsInputProps = {
  containerProps?: StackProps;
  fullwidth?: boolean;
  maintainGutters?: boolean;
  name: string;
};

export const FormTagsInput = ({
  containerProps = {},
  fullwidth = true,
  maintainGutters = true,
  name,
}: FormTagsInputProps) => {
  const filterFlag = useFeatureFlag('feature_tagging-search-and-filter');
  const [tagInput, setTagInput] = useState<string>('');
  const [field, meta, helpers] = useField(name);
  const error = meta?.touched && meta?.error;

  const checkTagInput = (e, autoSubmit) => {
    const value = e.target.value;
    if (autoSubmit || (value[value.length - 1] === ' ' && value.length > 1)) {
      if (!field.value.includes(value.trim())) {
        helpers.setValue([...field.value, value.trim()]);
      }
      setTagInput('');
    } else {
      setTagInput(value);
    }
  };

  const handleTagDelete = (tag: string) => {
    helpers.setValue(field.value.filter((t) => t !== tag));
  };

  if (!filterFlag) {
    return null;
  }

  return (
    <Stack sx={{ width: fullwidth ? '100%' : 'fit-content', ...containerProps.sx }}>
      <TextInput
        label="Tags"
        fullWidth={fullwidth}
        sx={{
          flexWrap: 'wrap',
          maxWidth: fullwidth ? null : '400px',
          pt: '3px',
          '& .MuiInputBase-input': { flex: 1, minWidth: '50px' },
        }}
        startAdornment={
          <Group flexWrap="wrap" rowGap={1}>
            {field.value.map((tag) => (
              <Tag
                key={tag}
                label={tag}
                onDelete={(e) => {
                  console.log('delete', e);
                  handleTagDelete(tag);
                }}
                sx={{
                  height: '21px',
                  '& .MuiChip-label': { fontSize: '12px', lineHeight: '21px' },
                }}
              />
            ))}
          </Group>
        }
        value={tagInput}
        onChange={(e) => {
          checkTagInput(e, false);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Backspace' && (e.target as HTMLInputElement).value === '') {
            handleTagDelete(field.value[field.value.length - 1]);
          }
          if (e.key === 'Tab' && (e.target as HTMLInputElement).value !== '') {
            checkTagInput(e, true);
          }
        }}
        onBlur={(e) => {
          if (e.target.value !== '') {
            checkTagInput(e, true);
          }
        }}
      />
      <FormFieldError error={error} maintainGutters={maintainGutters} />
    </Stack>
  );
};
