import { PlusIcon } from '@assets/icons';
import { Button } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Stack } from '@mui/material';
import posthog from 'posthog-js';
import { NewAnnotationMapModal, NewAnnotationMapModalHelpContent } from './NewAnnotationMapModal';

type AnnotationMapHeaderProps = {
  name: string;
  organizationId: string;
  refetch: VoidFunction;
};

export const AnnotationMapHeader = ({
  name,
  organizationId,
  refetch,
}: AnnotationMapHeaderProps) => {
  const { showModal: showNewAnnotationMapModal } = useModal({
    component: NewAnnotationMapModal,
    componentProps: { organizationId, refetch },
    modalProps: { title: 'New annotation map', help: NewAnnotationMapModalHelpContent },
  });

  return (
    <Stack spacing="2px">
      <Typography variant="caption2">{name}</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Annotation Maps</Typography>
        <Button
          startIcon={PlusIcon}
          onClick={() => {
            posthog.capture('user_create-annotation-map_intent', {
              location: 'organization-landing',
            });
            showNewAnnotationMapModal();
          }}
        >
          New annotation map
        </Button>
      </Stack>
    </Stack>
  );
};
