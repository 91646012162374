import { LoadingIcon } from '@assets/icons';
import { DeckardFilter, SearchAndFilter } from '@components/filter';
import { InfiniteScrollTableComponents, useGraphqlPagination } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import {
  GetVolumesQuery,
  Volume,
  VolumeFilter,
  useGetOrganizationSettingsQuery,
  useGetVolumesQuery,
  useGetVolumesSearchDetailQuery,
} from '@generated/UseGraphqlHooks';
import { useFeatureFlag } from '@hooks/FeatureFlags';
import { Box, Stack, useTheme } from '@mui/material';
import { find } from 'lodash';
import { useMemo, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { VolumeHeader } from './VolumeHeader';
import { VolumeTableHeader } from './VolumeTableHeader';
import { VolumeTableRow } from './VolumeTableRow';

type OrganizationVolumesProps = {
  name: string;
  organizationId: string;
};

export const OrganizationVolumes = ({ name, organizationId }: OrganizationVolumesProps) => {
  const { palette } = useTheme();

  const filterFlag = useFeatureFlag('feature_tagging-search-and-filter');
  const [filter, setFilter] = useState<DeckardFilter<VolumeFilter>>({
    dates: { type: 'date', value: [], display: [] },
    tags: { type: 'string', value: [], display: [] },
    texts: { type: 'string', value: [], display: [] },
    users: { type: 'exact', value: [], display: [] },
  });

  const queryFilter = useMemo(() => {
    return Object.entries(filter)
      .filter(([_, value]) => value.value.length > 0)
      .reduce((acc, [key, value]) => {
        acc[key] = value.value;
        return acc;
      }, {} as VolumeFilter);
  }, [filter]);
  const { data, refetch: refetchSearchDetail } = useGetVolumesSearchDetailQuery({
    variables: { organizationId, filters: queryFilter },
  });
  const searchDetail = data?.getVolumesSearchDetail;

  const {
    data: volumes,
    loading: volumesLoading,
    fetchMore: fetchMoreVolumes,
    refetch: refetchVolumes,
  } = useGraphqlPagination<GetVolumesQuery, Volume>(
    useGetVolumesQuery({
      variables: {
        organizationId,
        limit: 30,
        filters: queryFilter,
      },
    }),
    'getVolumes',
    'volumeId',
  );
  const { data: organizationSettingsData, loading: organizationSettingsLoading } =
    useGetOrganizationSettingsQuery({
      variables: { organizationId },
    });
  const { usage, limit } =
    (!organizationSettingsLoading &&
      find(organizationSettingsData?.getOrganizationSettings, {
        setting: 'Managed Volumes',
      })) ||
    {};

  const refetch = () => {
    refetchSearchDetail();
    refetchVolumes();
  };

  const tableRow = (_index, volume) => (
    <VolumeTableRow {...volume} selectedOrganizationId={organizationId} refetch={refetch} />
  );
  return (
    <Stack pt={5} pl={10} spacing={5} height="100%" overflow="hidden">
      <VolumeHeader
        organizationId={organizationId}
        usage={usage}
        limit={limit}
        name={name}
        refetch={refetch}
      />
      <Stack
        flex={1}
        overflow="hidden"
        gap={4}
        p={6}
        sx={{ backgroundColor: palette.background.paper }}
      >
        {filterFlag && (
          <SearchAndFilter
            filter={filter}
            setFilter={setFilter}
            searchDetail={searchDetail}
            variant="secondary"
          />
        )}
        {volumesLoading || organizationSettingsLoading ? (
          <Stack
            bgcolor={palette.background.paper}
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingIcon size={16} />
          </Stack>
        ) : volumes.length === 0 ? (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Typography>This organization has no volumes.</Typography>
          </Box>
        ) : (
          <TableVirtuoso
            data={volumes}
            endReached={fetchMoreVolumes}
            components={InfiniteScrollTableComponents as any}
            fixedHeaderContent={VolumeTableHeader}
            itemContent={tableRow}
            increaseViewportBy={400}
          />
        )}
      </Stack>
    </Stack>
  );
};
