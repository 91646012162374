import { LoadingIcon } from '@assets/icons';
import { DeckardFilter, SearchAndFilter } from '@components/filter';
import { InfiniteScrollTableComponents, useGraphqlPagination } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import {
  GanModel,
  GanModelFilter,
  GetGanModelsQuery,
  useGetGanModelsQuery,
  useGetGanModelsSearchDetailQuery,
} from '@generated/UseGraphqlHooks';
import { useFeatureFlag } from '@hooks/FeatureFlags';
import { Box, Stack, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { GanModelHeader } from './GanModelHeader';
import { GanModelTableHeader } from './GanModelTableHeader';
import { GanModelTableRow } from './GanModelTableRow';

type OrganizationGanModelsProps = {
  name: string;
  organizationId: string;
};

export const OrganizationGanModels = ({ name, organizationId }: OrganizationGanModelsProps) => {
  const { palette } = useTheme();

  const filterFlag = useFeatureFlag('feature_tagging-search-and-filter');
  const [filter, setFilter] = useState<DeckardFilter<GanModelFilter>>({
    dates: { type: 'date', value: [], display: [] },
    tags: { type: 'string', value: [], display: [] },
    texts: { type: 'string', value: [], display: [] },
    users: { type: 'exact', value: [], display: [] },
  });

  const queryFilter = useMemo(() => {
    return Object.entries(filter)
      .filter(([_, value]) => value.value.length > 0)
      .reduce((acc, [key, value]) => {
        acc[key] = value.value;
        return acc;
      }, {} as GanModelFilter);
  }, [filter]);
  const { data, refetch: refetchSearchDetail } = useGetGanModelsSearchDetailQuery({
    variables: { organizationId, filters: queryFilter },
  });
  const searchDetail = data?.getGANModelsSearchDetail;

  const {
    data: ganModels,
    loading: ganModelsLoading,
    fetchMore,
    refetch: refetchGanModels,
  } = useGraphqlPagination<GetGanModelsQuery, GanModel>(
    useGetGanModelsQuery({
      variables: { organizationId, limit: 30, filters: queryFilter },
    }),
    'getGANModels',
    'modelId',
  );

  const refetch = () => {
    refetchGanModels();
    refetchSearchDetail();
  };

  const tableRow = (_index, ganModel) => (
    <GanModelTableRow {...ganModel} selectedOrganizationId={organizationId} refetch={refetch} />
  );

  return (
    <Stack pt={5} pl={10} spacing={5} height="100%" overflow="hidden">
      <GanModelHeader organizationId={organizationId} name={name} refetch={refetch} />
      <Stack
        flex={1}
        overflow="hidden"
        gap={4}
        p={6}
        sx={{ backgroundColor: palette.background.paper }}
      >
        {filterFlag && (
          <SearchAndFilter
            filter={filter}
            setFilter={setFilter}
            searchDetail={searchDetail}
            variant="secondary"
          />
        )}
        {ganModelsLoading ? (
          <Stack
            bgcolor={palette.background.paper}
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingIcon size={16} />
          </Stack>
        ) : ganModels.length === 0 ? (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Typography>This Organization has no GAN Models.</Typography>
          </Box>
        ) : (
          <TableVirtuoso
            data={ganModels}
            endReached={fetchMore}
            components={InfiniteScrollTableComponents as any}
            fixedHeaderContent={GanModelTableHeader}
            itemContent={tableRow}
            increaseViewportBy={400}
          />
        )}
      </Stack>
    </Stack>
  );
};
