import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { FormTagsInput } from '@components/form/FormInputs/FormTagsInput';
import { useEditGraphMutation } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import * as yup from 'yup';

interface GraphsListViewPreviewMenuEditConfigurationProps {
  description?: string;
  graphId?: string;
  name?: string;
  tags?: string[];
  workspaceId?: string;
  onClose: () => void;
  refetch: () => void;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
});

export const GraphsListViewPreviewMenuEditConfiguration = ({
  description,
  graphId,
  name,
  tags,
  workspaceId,
  onClose,
  refetch,
}: GraphsListViewPreviewMenuEditConfigurationProps) => {
  const { useAsyncNotification } = useNotifications();
  const [editGraph] = useEditGraphMutation();

  const handleUpdateConfigSubmit = useAsyncNotification(
    'Updated configuration.',
    async ({
      name: updatedName,
      description: updatedDescription,
      tags: updatedTags,
    }: {
      name: string;
      description: string;
      tags: string[];
    }) => {
      await editGraph({
        variables: {
          workspaceId,
          graphId,
          name: updatedName,
          description: updatedDescription,
          tags: updatedTags,
        },
      });
      if (refetch) {
        refetch();
      }
      onClose();
    },
  );

  return (
    <Form
      enableReinitialize
      initialValues={{ name, description, tags: tags || [] }}
      validationSchema={validationSchema}
      onSubmit={handleUpdateConfigSubmit}
    >
      {({ handleSubmit }) => (
        <Stack>
          <FormTextInput name="name" label="Name" />
          <FormTextInput
            name="description"
            label="Description"
            multiline
            rows={3}
            inputProps={{ maxLength: 200 }}
          />
          <FormTagsInput name="tags" />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              onClick={handleSubmit}
              data-cy="Graphs-List-View-Preview-Menu-Edit-Configuration-Save-Button"
            >
              Save
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              data-cy="Graphs-List-View-Preview-Menu-Edit-Configuration-Cancel-Button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
