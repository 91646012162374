import { useApolloClient } from '@apollo/client';
import { LoadingIcon } from '@assets/icons';
import { useGetRecentGraphsQuery } from '@generated/UseGraphqlHooks';
import { Box } from '@mui/material';
import { GraphsGridViewPreview } from '../graphs';
import { WorkspaceRecentGraphsAddNewGraph } from './WorkspacesRecentGraphsAddNewGraph';

interface WorkspaceRecentGraphsProps {
  workspaceId: string;
}

export const WorkspaceRecentGraphs = ({ workspaceId }: WorkspaceRecentGraphsProps) => {
  const client = useApolloClient();
  const {
    data,
    loading,
    refetch: refetchRecentGraphs,
  } = useGetRecentGraphsQuery({
    variables: { workspaceId, staged: false },
    fetchPolicy: 'no-cache',
  });

  const recentGraphs = data?.getGraphs || [];

  if (loading)
    return (
      <Box height="100%" display="flex" justifyContent="center" alignItems="center">
        <LoadingIcon size={16} />
      </Box>
    );

  const refetch = () => {
    refetchRecentGraphs();
    client.cache.evict({ fieldName: 'getGraphsSearchDetail' });
  };

  return (
    <Box
      sx={{ overflowY: 'auto', overflowX: 'hidden', pr: 1 }}
      data-cy="Workspaces-Recent-Graph-Preview"
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
          gap: 6,
          paddingRight: 2,
        }}
      >
        <WorkspaceRecentGraphsAddNewGraph refetch={refetch} />
        {recentGraphs.map((graph) => (
          <GraphsGridViewPreview key={graph.graphId} graph={graph} refetch={refetch} />
        ))}
      </Box>
    </Box>
  );
};
