import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { FormTagsInput } from '@components/form/FormInputs/FormTagsInput';
import { Typography } from '@components/typography';
import {
  useCreateWorkspaceMutation,
  useValidateContentCodeLazyQuery,
} from '@generated/UseGraphqlHooks';
import { Stack, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { isEmpty } from 'lodash';
import posthog from 'posthog-js';
import { useState } from 'react';
import * as yup from 'yup';
import { NewWorkspacesModalAvailableChannelsDropdown } from './NewWorkspacesModalAvailableChannelDropdown';

const validationSchema = yup.object({
  workspaceName: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  contentCode: yup.string(),
});

interface NewWorkspacesModalProps {
  organizationId: string;
  organizationName: string;
  onClose: () => void;
  refetch: () => Promise<void>;
}
type CreateWorkspaceFormType = {
  workspaceName: string;
  description: string;
  channelIds: string[];
  volumeIds: string[];
  contentCode: string;
  tags: string[];
};

export const NewWorkspacesModal = ({
  organizationId,
  organizationName,
  onClose,
  refetch,
}: NewWorkspacesModalProps) => {
  const [contentCodeStatus, setContentCodeStatus] = useState<string>('');
  const { useAsyncNotification } = useNotifications();

  const [createWorkspaceMutation] = useCreateWorkspaceMutation();
  const [validateContentCode] = useValidateContentCodeLazyQuery();

  const createNewWorkspace = useAsyncNotification(
    'New workspace was created successfully',
    async ({
      workspaceName,
      description,
      channelIds,
      volumeIds,
      contentCode,
      tags,
    }: CreateWorkspaceFormType) => {
      await createWorkspaceMutation({
        variables: {
          organizationId,
          name: workspaceName,
          description,
          channelIds,
          volumeIds,
          code: contentCode,
          tags,
        },
      });
      posthog.capture('user_create-workspace_submitted');
      void refetch();
      onClose();
    },
  );

  const handleFormSubmit = async ({
    workspaceName,
    description,
    channelIds,
    contentCode,
    tags,
  }: CreateWorkspaceFormType) => {
    if (contentCode) {
      const result = await validateContentCode({ variables: { code: contentCode } });
      if (!result.data?.validateContentCode && !isEmpty(contentCode)) {
        setContentCodeStatus('Invalid content code');
        return;
      }
    }
    await createNewWorkspace({
      workspaceName,
      description,
      channelIds,
      volumeIds: [],
      contentCode,
      tags,
    });
  };

  return (
    <Form
      initialValues={{
        workspaceName: '',
        description: '',
        channelIds: [],
        volumeIds: [],
        contentCode: '',
        tags: [],
      }}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ isValid, handleSubmit, dirty, isSubmitting, values, setFieldValue }) => {
        return (
          <Stack>
            <Typography variant="caption2">Organization name</Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              {organizationName}
            </Typography>
            <FormTextInput name="workspaceName" label="Workspace name" />
            <FormTextInput name="description" label="Description" multiline rows={3} />
            <NewWorkspacesModalAvailableChannelsDropdown
              organizationId={organizationId}
              name="channelIds"
            />
            <FormTagsInput name="tags" />
            <FormTextInput name="contentCode" label="Content code (Optional)" />
            {contentCodeStatus !== '' && (
              <Typography variant="caption1" color="error" sx={{ paddingBottom: 6 }}>
                {contentCodeStatus}
              </Typography>
            )}
            <Stack gap={4}>
              <AsyncButton
                fullWidth
                loading={isSubmitting}
                disabled={!(isValid && dirty && !isSubmitting)}
                onClick={handleSubmit}
                data-cy="New-Workspace-Modal-Create-Button"
              >
                Create
              </AsyncButton>
              <Button
                fullWidth
                variant="secondary"
                data-cy="New-Workspace-Modal-Cancel-Button"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        );
      }}
    </Form>
  );
};

export const NewWorkspacesModalHelpContent = (
  <Typography variant="body2">
    A Workspace is a container for organizing work related to one set of projects or applications.
    Workspaces may be used as a collaboration device in that users can be invited to a Workspace.
    Your Workspace shows recent Graphs, recent Jobs, and recent Datasets you have worked on. When
    you first create a workspace you can associate it with one or more channels. After a workspace
    has been created, additional resources such as volumes, GAN models, and annotation maps can be
    associated with it. You can also optionally create a workspace from a content code which will
    provide an initial base set of resources and graphs to work with.
  </Typography>
);
