import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { FormTagsInput } from '@components/form/FormInputs/FormTagsInput';
import { useDuplicateGraphMutation } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { navigate } from 'gatsby';
import posthog from 'posthog-js';
import * as yup from 'yup';

interface DuplicateGraphProps {
  description?: string;
  graphId?: string;
  name?: string;
  tags?: string[];
  workspaceId?: string;
  onClose: () => void;
  refetch?: (newGraphId?: string) => void;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters.')
    .required('Name is required'),
});

export const GraphsListViewPreviewMenuDuplicateConfiguration = ({
  description: oldDescription,
  graphId,
  name: oldName,
  tags: oldTags,
  workspaceId,
  onClose,
  refetch,
}: DuplicateGraphProps) => {
  const { useAsyncNotification } = useNotifications();
  const [duplicateGraph] = useDuplicateGraphMutation();

  const handleDuplicationSubmit = useAsyncNotification(
    'Successfully copied graph.',
    async ({ name, description, tags }: { name: string; description: string; tags: string[] }) => {
      const {
        data: { duplicateGraph: duplicatedGraphId },
      } = await duplicateGraph({
        variables: { workspaceId, graphId, name, description, tags },
      });
      posthog.capture('user_copy-graph_submitted');
      if (refetch) {
        refetch(duplicatedGraphId);
      } else {
        await navigate(`/workspaces/${workspaceId}/graphs/${duplicatedGraphId}`);
      }
      onClose();
    },
  );

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={{
        name: `${oldName || ''} (Copy)`,
        description: oldDescription || '',
        tags: oldTags || [],
      }}
      onSubmit={handleDuplicationSubmit}
    >
      {({ handleSubmit }) => (
        <Stack>
          <FormTextInput name="name" label="Name" />
          <FormTextInput name="description" label="Description" multiline rows={3} />
          <FormTagsInput name="tags" />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              data-cy="Graphs-List-View-Preview-Menu-Duplicate-Configuration-Save-Button"
              onClick={handleSubmit}
            >
              Save
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              data-cy="Graphs-List-View-Preview-Menu-Duplicate-Configuration-Cancel-Button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
