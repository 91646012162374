import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { FormTagsInput } from '@components/form/FormInputs/FormTagsInput';
import { useEditManagedMapMutation } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import * as yup from 'yup';

interface EditAnnotationMapModalProps {
  onClose: () => void;
  mapId: string;
  name: string;
  description: string;
  tags: string[];
  refetch?: VoidFunction;
}

interface SubmitType {
  name: string;
  description: string;
  tags: string[];
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  description: yup.string(),
});

export const EditAnnotationMapModal = ({
  onClose,
  name: oldName,
  description: oldDescription,
  mapId,
  tags: oldTags,
  refetch,
}: EditAnnotationMapModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [editMap] = useEditManagedMapMutation();

  const handleFormSubmit = useAsyncNotification(
    'Successfully updated the map',
    async ({ name, description, tags }: SubmitType) => {
      await editMap({
        variables: { mapId, name, description, tags },
      });
      if (refetch) {
        refetch();
      }
      onClose();
    },
  );

  return (
    <Form
      enableReinitialize
      initialValues={{
        name: oldName || '',
        description: oldDescription || '',
        tags: oldTags || [],
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleFormSubmit}
    >
      {({ isValid, handleSubmit, dirty, isSubmitting }) => (
        <Stack>
          <FormTextInput name="name" label="Name" />
          <FormTextInput name="description" label="Description" multiline rows={3} />
          <FormTagsInput name="tags" />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              loading={isSubmitting}
              disabled={!(isValid && dirty && !isSubmitting)}
              onClick={handleSubmit}
            >
              Save
            </AsyncButton>
            <Button fullWidth variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
