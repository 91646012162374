import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { FormTagsInput } from '@components/form/FormInputs/FormTagsInput';
import { useEditUmapMutation } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import * as yup from 'yup';

interface DatasetsDetailsUmapEditModalProps {
  workspaceId: string;
  umapId: string;
  name: string;
  tags: string[];
  onClose: VoidFunction;
  refetch: VoidFunction;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
});

export const DatasetsDetailsUmapEditModal = ({
  name: umapName,
  tags: umapTags,
  umapId,
  workspaceId,
  onClose,
  refetch,
}: DatasetsDetailsUmapEditModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [editUmapMutation] = useEditUmapMutation();

  const handleEditUmap = useAsyncNotification(
    'Renamed the UMAP name successfully.',
    async ({ name, tags }: { name: string; tags: string[] }) => {
      await editUmapMutation({
        variables: {
          name,
          tags,
          workspaceId,
          umapId,
        },
      });
      void refetch();
      onClose();
    },
  );

  return (
    <Form
      onSubmit={handleEditUmap}
      initialValues={{ name: umapName, tags: umapTags }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Stack>
          <FormTextInput label="Name" name="name" />
          <FormTagsInput name="tags" />
          <Stack gap={4}>
            <AsyncButton fullWidth onClick={handleSubmit}>
              Save
            </AsyncButton>
            <Button fullWidth variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
