import { MoreVerticalIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Workspace } from '@generated/UseGraphqlHooks';
import { Box, Menu, MenuItem } from '@mui/material';
import {
  WorkspacesMoreDropdownChangeThumbnailModal,
  WorkspacesMoreDropdownDeleteModal,
  WorkspacesMoreDropdownEditModal,
} from '@subsets/workspaces';
import { navigate } from 'gatsby';
import { MouseEvent, useState } from 'react';
import { WorkspaceResourcesModal } from './resources/WorkspaceResourcesModal';

interface WorkspacesMoreButtonDropdownProps {
  workspace?: Omit<Workspace, 'organization'>;
  workspacesRefetch?: () => Promise<void> | void;
  color?: string;
  isLandingPageWorkspace: boolean;
}

export const WorkspacesMoreDropdown = ({
  workspace,
  workspacesRefetch,
  isLandingPageWorkspace,
}: WorkspacesMoreButtonDropdownProps) => {
  const { name, workspaceId, organizationId, description, tags } = workspace || {};
  const workspaceData = {
    workspaceName: name,
    description,
    tags,
    workspaceId,
    workspaceOrganizationId: organizationId,
    workspacesRefetch,
    isLandingPageWorkspace,
  };

  const { showModal: showWorkspaceResourcesModal } = useModal({
    component: WorkspaceResourcesModal,
    componentProps: { name, organizationId, workspaceId },
    modalProps: { title: `${name} resources`, sx: { maxWidth: '875px', width: '90%' } },
  });

  const { showModal: showEditWorkspaceModal } = useModal({
    component: WorkspacesMoreDropdownEditModal,
    componentProps: { ...workspaceData, refetch: workspacesRefetch },
    modalProps: { title: 'Edit workspace' },
  });

  const { showModal: showChangeThumbnailModal } = useModal({
    component: WorkspacesMoreDropdownChangeThumbnailModal,
    componentProps: { ...workspaceData },
    modalProps: { title: 'Change thumbnail', sx: { maxWidth: '875px', width: '90%' } },
  });

  const { showModal: showDeleteWorkspaceModal } = useModal({
    component: WorkspacesMoreDropdownDeleteModal,
    componentProps: { ...workspaceData },
    modalProps: { title: `Delete ${name}` },
  });
  const [anchorEl, setAnchorEl] = useState<Element>(null);
  const open = Boolean(anchorEl);

  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget as Element);
  };

  const handleDeleteOpen = () => {
    showDeleteWorkspaceModal();
    handleMenuClose();
  };

  const handleChannelsOpen = () => {
    showWorkspaceResourcesModal();
    handleMenuClose();
  };
  const handleChangeThumbnail = () => {
    showChangeThumbnailModal();
    handleMenuClose();
  };

  const handleRenameOpen = () => {
    showEditWorkspaceModal();
    handleMenuClose();
  };

  const handleSettingsButton = async () => {
    await navigate(`/organizations/${organizationId as string}/settings`);
  };

  return (
    <Box>
      <IconButton
        onClick={handleMenuClick}
        data-cy="Workspace-More-Dropdown"
        Icon={MoreVerticalIcon}
        size={20}
      />

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box>
          <MenuItem onClick={handleRenameOpen}>
            <Typography variant="caption2" data-cy="Workspaces-More-Dropdown-Rename">
              Edit
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleChannelsOpen}>
            <Typography variant="caption2" data-cy="Workspaces-More-Dropdown-Resources">
              Resources
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleSettingsButton}>
            <Typography variant="caption2" data-cy="Workspaces-More-Dropdown-Settings">
              Settings
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleChangeThumbnail}>
            <Typography variant="caption2" data-cy="Workspaces-More-Dropdown-Change-Thumbnail">
              Thumbnail
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleDeleteOpen}>
            <Typography variant="caption2" data-cy="Workspaces-More-Dropdown-Delete">
              Delete
            </Typography>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};
