import { LoadingIcon } from '@assets/icons';
import { TableView } from '@components/button';
import { DeckardFilter } from '@components/filter';
import { InfiniteScrollTableComponents, useGraphqlPagination } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import {
  GetWorkspacesQuery,
  Organization,
  Workspace,
  WorkspaceFilter,
  useGetWorkspacesQuery,
  useGetWorkspacesSearchDetailQuery,
} from '@generated/UseGraphqlHooks';
import { calculateDateDifference } from '@helper-functions/calculateDateDifference';
import { useFeatureFlag } from '@hooks/FeatureFlags';
import { Box, Grid, useTheme } from '@mui/material';
import { LandingPageExpirationWrapper } from '@subsets/expiration';
import {
  LandingPageOrganizationDetails,
  LandingPageWorkspacesHeader,
  LandingPageWorkspacesWorkspacePreview,
  WorkspaceTableHeader,
  WorkspaceTableRow,
} from '@subsets/landing-page';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { TableVirtuoso, VirtuosoGrid } from 'react-virtuoso';

const getOrganizationExpirationData = (organizations: Organization[], organizationId: string) => {
  const workspaceOrganization = organizations.find(
    (organization) => organization.organizationId === organizationId,
  );
  const { days, hours } = calculateDateDifference(workspaceOrganization?.expirationDate);
  return {
    expirationDate: { days, hours },
    expired: workspaceOrganization?.expired || hours < 1,
  };
};

const ItemContainer = (props) => (
  <Box
    data-test-id="virtuoso-item-list"
    sx={{
      display: 'flex',
      flex: 'none',
      alignContent: 'stretch',
      boxSizing: 'border-box',
    }}
    {...props}
  />
);

const ListContainer = forwardRef<HTMLDivElement>((props, ref) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      gap: 6,
      paddingRight: 2,
    }}
    {...props}
    ref={ref}
  />
));

type LandingPageWorkspacesProps = {
  label: string;
  organization?: Organization;
  organizations: Organization[];
  recent?: boolean;
};

export const LandingPageWorkspaces = ({
  label,
  organization,
  organizations,
  recent = false,
}: LandingPageWorkspacesProps) => {
  const { palette } = useTheme();

  const filterFlag = useFeatureFlag('feature_tagging-search-and-filter');
  const [filter, setFilter] = useState<DeckardFilter<WorkspaceFilter>>({
    channels: { type: 'exact', value: [], display: [] },
    dates: { type: 'date', value: [], display: [] },
    tags: { type: 'string', value: [], display: [] },
    texts: { type: 'string', value: [], display: [] },
    users: { type: 'exact', value: [], display: [] },
  });

  const queryFilter = useMemo(() => {
    console.log('update query filter');
    return Object.entries(filter)
      .filter(([_, value]) => value.value.length > 0)
      .reduce((acc, [key, value]) => {
        acc[key] = value.value;
        return acc;
      }, {} as WorkspaceFilter);
  }, [filter]);
  const { data, refetch: refetchSearchDetail } = useGetWorkspacesSearchDetailQuery({
    variables: {
      organizationId: organization?.organizationId,
      filters: filterFlag ? queryFilter : null,
    },
  });
  const searchDetail = data?.getWorkspacesSearchDetail;

  const [tableView, setTableView] = useState<TableView>('grid');
  const {
    data: workspaces,
    loading: workspacesLoading,
    fetchMore: fetchMoreWorkspace,
    refetch: refetchWorkspaces,
  } = useGraphqlPagination<GetWorkspacesQuery, Workspace>(
    useGetWorkspacesQuery({
      variables: {
        organizationId: recent ? null : organization?.organizationId,
        filters: filterFlag ? queryFilter : null,
        limit: 30,
      },
      fetchPolicy: recent ? 'no-cache' : 'cache-first',
    }),
    'getWorkspaces',
    'workspaceId',
  );

  const refetch = () => {
    refetchWorkspaces();
    refetchSearchDetail();
  };

  const tableRow = (_index, workspace) => <WorkspaceTableRow {...workspace} refetch={refetch} />;

  const item = useCallback(
    (index: number, workspace: any) => {
      const { expired } =
        getOrganizationExpirationData(organizations, workspace.organizationId) || {};
      return (
        <LandingPageWorkspacesWorkspacePreview
          recent={recent}
          key={workspace?.workspaceId}
          expired={recent && expired}
          {...workspace}
        />
      );
    },
    [recent],
  );

  return (
    <>
      <Grid container height="100%">
        <Grid
          item
          xs={recent ? 12 : 8.5}
          pl={10}
          pt={10}
          pr={recent ? 0 : 10}
          sx={{ height: '100%', overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}
        >
          <LandingPageWorkspacesHeader
            label={label}
            tableView={tableView}
            filter={filter}
            organizationId={organization?.organizationId}
            organizationName={organization?.name}
            searchDetail={searchDetail}
            refetch={refetch}
            setFilter={setFilter}
            setTableView={setTableView}
          />
          {workspaces?.length > 0 ? (
            tableView === 'grid' ? (
              <VirtuosoGrid
                totalCount={workspaces?.length}
                endReached={() => {
                  fetchMoreWorkspace();
                }}
                components={{
                  Item: ItemContainer,
                  List: ListContainer as any,
                }}
                data={workspaces}
                itemContent={item}
              />
            ) : (
              <Box
                flex={1}
                data-test-id="virtuoso-item-list"
                sx={{ backgroundColor: palette.background.paper, p: 6 }}
              >
                <TableVirtuoso
                  data={workspaces}
                  endReached={fetchMoreWorkspace}
                  components={InfiniteScrollTableComponents as any}
                  fixedHeaderContent={WorkspaceTableHeader}
                  itemContent={tableRow}
                  increaseViewportBy={400}
                />
              </Box>
            )
          ) : (
            <Box display="flex" height="90%" alignItems="center" justifyContent="center">
              {workspacesLoading ? (
                <LoadingIcon size={16} />
              ) : workspaces.length === 0 ? (
                <Typography>Your organization has no workspaces.</Typography>
              ) : (
                <Typography>No workspaces found that match your search.</Typography>
              )}
            </Box>
          )}
        </Grid>
        {!recent && (
          <LandingPageOrganizationDetails
            organizationId={organization?.organizationId}
            organizationRole={organization?.role}
          />
        )}
      </Grid>
      {!recent && <LandingPageExpirationWrapper {...organization} />}
    </>
  );
};
