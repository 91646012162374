import { FitInWindowIcon, GridIcon, ZoomInIcon, ZoomOutIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Tooltip } from '@components/tooltip';
import { Typography } from '@components/typography';
import { Divider, Stack, useTheme } from '@mui/material';
import { GraphDataContext, PositionContext, ZoomContext } from '@subsets/workspaces';
import { useContext } from 'react';
import { useObservable } from 'react-use';

export const ZoomController = () => {
  const { palette } = useTheme();
  const { zoomTo, percent, centerGraphElements } = useContext(ZoomContext);
  const { autoLayout } = useContext(PositionContext);
  const { graphMetadata } = useContext(GraphDataContext);
  const disabled = graphMetadata? graphMetadata.readOnly : true;

  const handleChange = (percentage: number) => {
    if (percentage < 0) {
      zoomTo(0, 0);
      return;
    }
    if (percentage > 200) {
      zoomTo(200, 0);
      return;
    }
    const value = Math.round(percentage);
    zoomTo(value, 0);
  };

  const customPopperProps =
    palette.mode === 'dark'
      ? {
          sx: { '& .MuiTooltip-tooltip': { backgroundColor: palette.background.paper } },
        }
      : {};

  const value = useObservable(percent);
  const zoomPercent = Math.min(Math.max(Number(value?.toFixed(0)), 0), 200);
  return (
    <Stack
      position="absolute"
      bottom={0}
      right={16}
      borderRadius="4px"
      gap={2}
      sx={{
        padding: 2,
        border: `1px solid ${palette.grey[500]}`,
        backgroundColor: palette.background.paper,
        width: 'fit-content',
      }}
      justifyContent="center"
    >
      <Typography variant="body2" align="center">{`${zoomPercent}%`}</Typography>
      <Tooltip title="Full extent" placement="right" PopperProps={customPopperProps}>
        <IconButton onClick={centerGraphElements} size={20} Icon={FitInWindowIcon} />
      </Tooltip>
      <Tooltip title="Zoom in" placement="right" PopperProps={customPopperProps}>
        <IconButton onClick={() => handleChange(value + 10)} size={20} Icon={ZoomInIcon} />
      </Tooltip>
      <Tooltip title="Zoom out" placement="right" PopperProps={customPopperProps}>
        <IconButton onClick={() => handleChange(value - 10)} size={20} Icon={ZoomOutIcon} />
      </Tooltip>

      {!disabled && (
        <>
          <Divider sx={{ mx: 2, borderColor: palette.text.primary }} />
          <Tooltip title="Auto layout" placement="right" PopperProps={customPopperProps}>
            <IconButton onClick={() => autoLayout()} size={20} Icon={GridIcon} />
          </Tooltip>
        </>
      )}
    </Stack>
  );
};
