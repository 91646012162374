import { LoadingIcon } from '@assets/icons';
import { Button } from '@components/button';
import { DeckardFilter, SearchAndFilter } from '@components/filter';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import {
  DatasetJob,
  DatasetJobFilter,
  DatasetJobSearchDetail,
  useClearWorkspaceJobsMutation,
} from '@generated/UseGraphqlHooks';
import { useFeatureFlag } from '@hooks/FeatureFlags';
import { Box, Stack, useTheme } from '@mui/material';
import { useCurrentWorkspace } from '@navigation/WorkspaceContext';
import { JobsManagerJobPreview } from '@subsets/workspaces';
import { useCallback } from 'react';
import { Virtuoso } from 'react-virtuoso';

interface JobsManagerProps {
  data: DatasetJob[];
  fetchMore: VoidFunction;
  loading: boolean;
  refetch: VoidFunction;
  filter: DeckardFilter<DatasetJobFilter>;
  setFilter: (filter: DeckardFilter<DatasetJobFilter>) => void;
  searchDetail: DatasetJobSearchDetail;
}

export const JobsManager = ({
  data: jobs,
  filter,
  loading,
  searchDetail,
  fetchMore,
  refetch,
  setFilter,
}: JobsManagerProps) => {
  const { palette } = useTheme();
  const filterFlag = useFeatureFlag('feature_tagging-search-and-filter');

  const {
    workspace: { workspaceId },
  } = useCurrentWorkspace();
  const [clearWorkspaceJobs] = useClearWorkspaceJobsMutation({ variables: { workspaceId } });

  const item = useCallback(
    (_index, job: DatasetJob) => <JobsManagerJobPreview job={job} refetch={refetch} />,
    [],
  );

  const clearJobs = async () => {
    await clearWorkspaceJobs();
    void refetch();
  };

  return (
    <Stack height="100%" gap={filterFlag ? 4 : 0}>
      <Group alignItems="center" justifyContent="space-between" pb={filterFlag ? 0 : 10}>
        <Typography variant="h3" color={palette.text.paper}>
          Running jobs
        </Typography>
        <Button variant="secondary" onClick={clearJobs}>
          Clear finished jobs
        </Button>
      </Group>
      {filterFlag && (
        <SearchAndFilter
          filter={filter}
          setFilter={setFilter}
          searchDetail={searchDetail}
          variant="secondary"
        />
      )}
      {loading ? (
        <Group height="100%" alignItems="center" justifyContent="center">
          <LoadingIcon size={16} />
        </Group>
      ) : jobs.length === 0 ? (
        <Group height="100%" alignItems="center" justifyContent="center">
          <Typography>You have no jobs.</Typography>
        </Group>
      ) : (
        <Box
          data-test-id="virtuoso-item-list"
          style={{ overflowY: 'auto', overflowX: 'hidden', flex: 1 }}
        >
          <Virtuoso
            totalCount={jobs.length}
            overscan={1000}
            endReached={fetchMore}
            data={jobs}
            itemContent={item}
          />
        </Box>
      )}
    </Stack>
  );
};
