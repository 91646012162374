import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { FormTagsInput } from '@components/form/FormInputs/FormTagsInput';
import { useEditStagedGraphMutation } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { FormikProps } from 'formik';
import * as yup from 'yup';

interface JobsConfigurationsOptionsEditModalProps {
  onClose: () => void;
  graphId: string;
  graphName: string;
  graphDescription: string;
  graphTags: string[];
  workspaceId: string;
  refetch: () => void;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters.')
    .required('Name is required'),
});

export const JobsConfigurationsOptionsEditModal = ({
  onClose,
  graphId,
  graphName,
  graphDescription,
  graphTags,
  workspaceId,
  refetch,
}: JobsConfigurationsOptionsEditModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [updateGraph] = useEditStagedGraphMutation();
  const handleSubmit = useAsyncNotification(
    'Edited the staged graph successfully.',
    async ({ name, description, tags }: { name: string; description: string; tags: string[] }) => {
      await updateGraph({ variables: { graphId, workspaceId, name, description, tags } });
      refetch();
      onClose();
    },
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        name: graphName || '',
        description: graphDescription || '',
        tags: graphTags || [],
      }}
      validationSchema={validationSchema}
    >
      {({ errors }: FormikProps<{ graphName: string }>) => (
        <Stack>
          <FormTextInput label="Graph name" name="name" />
          <FormTextInput label="Description" name="description" multiline rows={3} />
          <FormTagsInput name="tags" />
          <Stack gap={4}>
            <AsyncButton fullWidth type="submit" disabled={!!errors?.graphName}>
              Save
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              data-cy="Jobs-Configurations-Options-Rename-Modal-Cancel-Button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
