import { CopyIconButton } from '@components/button';
import { Typography } from '@components/typography';
import { formatTime } from '@helper-functions/time';
import { Box, TableCell } from '@mui/material';
import { upperFirst } from 'lodash';
import { memo } from 'react';
import { VolumeTableRowDropdown } from './VolumeTableRowDropdown';

interface VolumeTableRowProps {
  name: string;
  description?: string;
  updatedAt?: string;
  volumeId?: string;
  organization?: string;
  selectedOrganizationId?: string;
  permission?: string;
  size?: string;
  tags?: string[];
  refetch?: VoidFunction;
}

export const VolumeTableRow = memo((props: VolumeTableRowProps) => {
  const { name, volumeId, description, organization, updatedAt, permission, size } = props;

  return (
    <>
      <TableCell>
        <CopyIconButton value={volumeId} showValue />
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" data-cy="Volume-Table-Row-Name" pr={4}>
          {name || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4}>
          {description || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2">
          {upperFirst(permission) || 'Loading...'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4}>
          {`${Number(size || '0').toFixed(3)}Gb` || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4}>
          {organization || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography noWrap handleOverFlow variant="body2">
            {formatTime(updatedAt, 'date')}
          </Typography>
          <Box pr={4}>
            <VolumeTableRowDropdown {...props} />
          </Box>
        </Box>
      </TableCell>
    </>
  );
});
