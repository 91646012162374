import { CopyIconButton } from '@components/button';
import { Typography } from '@components/typography';
import { formatTime } from '@helper-functions/time';
import { Box, TableCell } from '@mui/material';
import { memo } from 'react';
import { AnnotationMapTableRowDropdown } from './AnnotationMapTableRowDropdown';

interface AnnotationMapTableRowProps {
  name: string;
  mapId?: string;
  organization?: string;
  organizationId?: string;
  selectedOrganizationId?: string;
  updatedAt?: string;
  description?: string;
  refetch?: VoidFunction;
}

export const AnnotationMapTableRow = memo((props: AnnotationMapTableRowProps) => {
  const { name, updatedAt, mapId, description, organization } = props;

  return (
    <>
      <TableCell>
        <CopyIconButton value={mapId} showValue />
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4}>
          {name || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4}>
          {description || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4}>
          {organization}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography noWrap handleOverFlow variant="body2" pr={4}>
            {formatTime(updatedAt, 'date') || '-'}
          </Typography>
          <Box pr={4}>
            <AnnotationMapTableRowDropdown {...props} />
          </Box>
        </Box>
      </TableCell>
    </>
  );
});
