import { LoadingIcon } from '@assets/icons';
import { CopyIconButton } from '@components/button';
import { InternalLink } from '@components/link';
import { Typography } from '@components/typography';
import {
  Workspace,
  useGetChannelsQuery,
  useGetOrganizationSettingsQuery,
  useGetWorkspacesQuery,
} from '@generated/UseGraphqlHooks';
import { formatTime } from '@helper-functions/time';
import { Box, TableCell } from '@mui/material';
import { WorkspacesMoreDropdown } from '@subsets/workspaces';
import { map, size } from 'lodash';
import { memo, useMemo } from 'react';

interface WorkspaceTableRowProps {
  workspaceId: string;
  name?: string;
  status?: string;
  organizationId?: string;
  updatedAt?: string;
  refetch?: VoidFunction;
}

export const WorkspaceTableRow = memo((props: WorkspaceTableRowProps) => {
  const { name, status, workspaceId, updatedAt, organizationId, refetch } = props;

  const { data: channelsData, loading: channelsLoading } = useGetChannelsQuery({
    variables: {
      workspaceId,
    },
  });
  const { refetch: organizationWorkspacesRefetch } = useGetWorkspacesQuery({
    variables: { organizationId },
  });
  const { refetch: organizationSettingsRefetch } = useGetOrganizationSettingsQuery({
    variables: { organizationId },
  });

  const workspacesRefetch = async () => {
    await organizationWorkspacesRefetch();
    await organizationSettingsRefetch();
    if (refetch) refetch();
  };

  const channels = useMemo(() => {
    const workspaceChannels = channelsData?.getChannels || [];
    if (workspaceChannels.length === 0) return null;
    const channelsNames = map(workspaceChannels, (channel) => channel.name);
    const channelString =
      size(channelsNames) > 3
        ? `${channelsNames.slice(0, 3).join(', ')}, +${size(workspaceChannels) - 3} more`
        : channelsNames.join(', ');
    return channelString;
  }, [channelsData]);

  return (
    <>
      <TableCell>
        <CopyIconButton value={workspaceId} showValue />
      </TableCell>
      <TableCell>
        <InternalLink to={`/workspaces/${workspaceId}`} unstyled>
          <Typography noWrap handleOverFlow variant="body2" pr={4}>
            {`${name}${status !== 'ready' ? ' (Syncing)' : ''}` || '-'}
          </Typography>
        </InternalLink>
      </TableCell>
      <TableCell>
        {channelsLoading ? (
          <LoadingIcon size={14} />
        ) : (
          <Typography noWrap handleOverFlow variant="body2" pr={4}>
            {channels || '-'}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography noWrap handleOverFlow variant="body2">
            {formatTime(updatedAt, 'date')}
          </Typography>
          <Box pr={4}>
            <WorkspacesMoreDropdown
              workspace={props as Workspace}
              workspacesRefetch={workspacesRefetch}
              isLandingPageWorkspace
            />
          </Box>
        </Box>
      </TableCell>
    </>
  );
});
