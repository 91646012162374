import { LoadingIcon } from '@assets/icons';
import { DeckardFilter, SearchAndFilter } from '@components/filter';
import { useGraphqlPagination } from '@components/infiniteScroll';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import {
  GetStagedGraphsQuery,
  Graph,
  GraphFilter,
  useGetGraphsSearchDetailQuery,
  useGetStagedGraphsQuery,
} from '@generated/UseGraphqlHooks';
import { useFeatureFlag } from '@hooks/FeatureFlags';
import { Stack, useTheme } from '@mui/material';
import { JobsConfigurationsPreviews } from '@subsets/workspaces';
import { useMemo, useState } from 'react';

interface JobsStagedJobsProps {
  workspaceId: string;
  refetch: (additional: number) => void;
}

export const JobsStagedJobs = ({ workspaceId, refetch: refetchJobs }: JobsStagedJobsProps) => {
  const { palette } = useTheme();

  const filterFlag = useFeatureFlag('feature_tagging-search-and-filter');
  const [filter, setFilter] = useState<DeckardFilter<GraphFilter>>({
    channels: { type: 'exact', value: [], display: [] },
    dates: { type: 'date', value: [], display: [] },
    tags: { type: 'string', value: [], display: [] },
    texts: { type: 'string', value: [], display: [] },
    users: { type: 'exact', value: [], display: [] },
  });

  const queryFilter = useMemo(() => {
    return Object.entries(filter)
      .filter(([_, value]) => value.value.length > 0)
      .reduce((acc, [key, value]) => {
        acc[key] = value.value;
        return acc;
      }, {} as GraphFilter);
  }, [filter]);
  const { data, refetch: refetchSearchDetail } = useGetGraphsSearchDetailQuery({
    variables: { workspaceId, staged: true, filters: queryFilter },
  });
  const searchDetail = data?.getGraphsSearchDetail;

  const {
    data: graphs,
    loading,
    fetchMore,
    refetch: refetchStagedGraphs,
  } = useGraphqlPagination<GetStagedGraphsQuery, Graph>(
    useGetStagedGraphsQuery({
      variables: { workspaceId, staged: true, limit: 30, filters: queryFilter },
    }),
    'getGraphs',
    'graphId',
  );

  const refetch = () => {
    void refetchSearchDetail();
    void refetchStagedGraphs();
  };

  return (
    <Stack height="100%" gap={filterFlag ? 4 : 10}>
      <Typography variant="h3" color={palette.text.paper} height="34px">
        Staged graphs
      </Typography>
      {filterFlag && (
        <SearchAndFilter filter={filter} setFilter={setFilter} searchDetail={searchDetail} />
      )}
      {loading ? (
        <Group height="100%" alignItems="center" justifyContent="center">
          <LoadingIcon size={16} />
        </Group>
      ) : graphs.length > 0 ? (
        <JobsConfigurationsPreviews
          graphs={graphs}
          refetch={refetch}
          fetchMore={fetchMore}
          refetchJobs={refetchJobs}
        />
      ) : (
        <Group height="100%" alignItems="center" justifyContent="center">
          <Typography>No staged graphs found.</Typography>
        </Group>
      )}
    </Stack>
  );
};
