import { GraphqlPagination, InfiniteScrollTableComponents } from '@components/infiniteScroll';
import { Graph } from '@generated/UseGraphqlHooks';
import { Box, useTheme } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import { GraphsListViewHeader } from './GraphsListViewHeader';
import { GraphsListViewPreview } from './GraphsListViewPreview';

interface GraphsListViewProps {
  pagination: GraphqlPagination<Graph>;
  refetch: VoidFunction;
}

export const GraphsListView = ({
  pagination: { data, fetchMore },
  refetch,
}: GraphsListViewProps) => {
  const { palette } = useTheme();
  const tableRow = (_index, graph: Graph) => (
    <GraphsListViewPreview graph={graph} refetch={refetch} />
  );
  return (
    <Box
      p={4}
      flex={1}
      data-test-id="virtuoso-item-list"
      sx={{ backgroundColor: palette.background.paper }}
    >
      <TableVirtuoso
        data={data}
        endReached={fetchMore}
        // there is a type mismatch with the expected ref and the actual ref, this does not affect functionality
        components={InfiniteScrollTableComponents as any}
        fixedHeaderContent={GraphsListViewHeader}
        itemContent={tableRow}
        increaseViewportBy={100}
      />
    </Box>
  );
};
